


































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { Stop, RouteStop, RouteStopSearch } from "@/store/models/meta";
import { Route } from "@/store/models/route";
import metaStore from "@/store/modules/meta";
import { AdminRouter } from "@/utils/routePathContsant";
import draggable from "vuedraggable";

@Component({
  components: {
    draggable,
  },
})
export default class AddStopsView extends Vue {
  route_name_title: Route = {} as Route;
  route_name: string = "";
  selectedStops: any = [];
  finalSelectedStops: Stop[] = [];

  isHidden: Boolean = true;
  isRouteSelect: Boolean = true;
  isStopSearched: Boolean = false;
  isStopCreate: Boolean = false;

  stopList: Stop[] = [];
  routeStopList: RouteStop[] = [];

  selectRoute: Route[] = [];
  selectedRoute = "";

  searchedStops: RouteStop[] = [];
  checkedStops: any = [];

  stop: Stop = {} as Stop;

  params: RouteStopSearch = {} as RouteStopSearch;

  async created() {
    const id: any = this.$route.query["id"];

    await metaStore.getRouteById(id);
    this.route_name_title = metaStore.route;

    await metaStore.loadRoutesDD();
    this.selectRoute = metaStore.routesList;
    const route_name: any = metaStore.routesList.find(
      (e) => e.gid === parseInt(id)
    );
    this.route_name = route_name.name;

    await metaStore.loadStop();
    this.stopList = metaStore.stopList;

    if (id) {
      this.params.route = parseInt(id);
      await metaStore.getRouteStop(this.params);
      const list = metaStore.routeStopList.sort((a, b) => a.id - b.id);
      this.routeStopList = list;
    }
  }

  get AdminRouter() {
    return AdminRouter;
  }

  stopRemoteMethod(query: any) {
    if (query !== "") {
      setTimeout(() => {
        this.stopList = metaStore.stopList.filter((item) => {
          return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }, 200);
    } else {
      this.stopList = metaStore.stopList;
    }
  }

  onRouteSearch() {
    this.isRouteSelect = !this.isRouteSelect;
  }

  onStopCreation() {
    this.isStopCreate = !this.isStopCreate;
  }

  routeStopRemoteMethod(query: any) {
    if (query !== "") {
      setTimeout(() => {
        this.selectRoute = metaStore.routesList.filter((item) => {
          return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      }, 200);
    } else {
      this.selectRoute = metaStore.routesList;
    }
  }

  async onStopSearch() {
    const id: any = this.selectedRoute;
    this.params.route = parseInt(id);
    await metaStore.getRouteStop(this.params);
    this.searchedStops = metaStore.routeStopList;
    this.isStopSearched = true;
  }

  async onStopClear() {
    this.searchedStops = [];
    this.selectedRoute = "";
    this.checkedStops = [];
    this.isStopSearched = false;
  }

  async onSubmitStop() {
    if (!this.stop.name || !this.stop.type) {
      this.$snotify.warning("Please give both Stop Name and Type");
      return;
    }
    await metaStore.createStop(this.stop);
    this.stop = {} as Stop;
    await metaStore.loadStop();
    this.stopList = metaStore.stopList;
  }

  onClearForm() {
    this.stop = {} as Stop;
  }

  async onConfirmStop() {
    const isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    const totalStops = this.selectedStops.concat(this.checkedStops);
    const result = metaStore.stopList.filter(
      (e) => totalStops.indexOf(e.gid) > -1
    );
    this.isHidden = false;
    this.finalSelectedStops = result;
  }

  async onCreate() {
    const isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    if (this.finalSelectedStops.length <= 0) {
      this.$snotify.error("Please select Stops before creating");
      return;
    }
    const id: any = this.$route.query["id"];
    const createList: RouteStop[] = [];
    for (let x of this.finalSelectedStops) {
      const routeStop: RouteStop = {} as RouteStop;
      routeStop.route = id;
      routeStop.stop = x.gid;
      createList.push(routeStop);
    }
    await metaStore.createRouteStop(createList);
    this.$router.push(AdminRouter.RouteInfo);
  }

  onClear() {
    this.finalSelectedStops = [];
    this.selectedStops = [];
    this.onStopClear();
  }
}
